<template>
  <div class="mt-5">
    <h1 class="font-semibold text-xl mb-5 flex items-center">
      Продукты ({{ totalProducts }})
      <i v-if="loading" class="el-icon-loading"></i>
    </h1>

    <el-row :gutter="20">
      <el-col
        v-for="product in products"
        :key="product.id"
        :xs="24"
        :sm="8"
        :md="6"
      >
        <ProductItem :product="product" />
      </el-col>
    </el-row>

    <el-pagination
      class="text-center my-5"
      background
      :disabled="loading"
      layout="prev, pager, next"
      :total="totalProducts"
      :page-size="filters.limit"
      :current-page="currentPage"
      @current-change="(val) => handleFiltersChange(val)"
    >
    </el-pagination>
  </div>
</template>
<script>
import { getProducts } from "@/api/product";
import { numberFormat, dateTimeFormat } from "@/helpers/formatter";
import ProductItem from "@/views/Products/shared/components/ProductItem.vue";

export default {
  name: "Home",
  components: {
    ProductItem,
  },
  data: () => ({
    products: [],
    totalProducts: 0,

    loading: false,

    filters: {
      limit: 20,
      offset: 0,
      query: null,
    },
  }),
  computed: {
    limit() {
      return this.$route.query.limit;
    },
    offset() {
      return this.$route.query.offset;
    },
    query() {
      return this.$route.query.query;
    },
    user() {
      return this.$store.state.user;
    },
    currentPage: {
      get() {
        return this.filters.offset / this.filters.limit + 1;
      },
    },
  },
  async created() {
    if (this.query) {
      this.filters.query = this.query;
    }
    if (this.limit) {
      this.filters.limit = +this.limit;
    }
    if (this.offset) {
      this.filters.offset = +this.offset;
    }
    await this.getProducts();
  },
  methods: {
    async getProducts() {
      this.loading = true;
      const result = await getProducts(this.filters);
      const { goods, total } = result;
      this.products = goods;
      this.totalProducts = total;
      this.loading = false;
    },

    numberFormat(number) {
      return numberFormat(number);
    },
    dateTimeFormat(datetime) {
      return dateTimeFormat(datetime * 1000);
    },
    changeUrlSearchParams() {
      this.$router.push({
        query: {
          limit: this.filters.limit,
          offset: this.filters.offset,
          query: this.filters.query,
        },
      });
    },
    async handleFiltersChange(page) {
      console.log("page", page);
      this.filters.offset = this.filters.limit * (page - 1);

      this.changeUrlSearchParams();
      await this.getProducts();
    },
  },
};
</script>

<style lang="scss" scoped></style>
