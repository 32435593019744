<template>
  <div>
    <h1 class="mt-5 text-3xl font-semibold">История заказов</h1>
    <section class="cart mt-5">
      <el-table :data="orders" stripe border>
        <el-table-column width="100" prop="id" label="ID"></el-table-column>
        <el-table-column prop="order_code" label="Номер заказа">
        </el-table-column>
        <el-table-column prop="order_code" label="Дата заказа">
          <template slot-scope="scope">{{
            dateFormat(scope.row.created_at)
          }}</template>
        </el-table-column>
        <el-table-column prop="total" label="Сумма заказа">
          <template slot-scope="scope"
            >{{ numberFormat(scope.row.total) }} тг</template
          >
        </el-table-column>
      </el-table>
    </section>
  </div>
</template>

<script>
import { getOrders } from "@/api/order";
import { format } from "date-fns";
export default {
  name: "Orders",
  data() {
    return {
      orders: [],
    };
  },
  async mounted() {
    const { orders } = await getOrders();
    this.orders = orders;
  },
  methods: {
    numberFormat(number) {
      return new Intl.NumberFormat().format(Math.round(number));
    },
    dateFormat(timestamp) {
      return format(timestamp * 1000, "dd.MM HH:mm");
    },
  },
};
</script>

<style scoped></style>
