<template>
  <div>
    <h1 class="mt-5 text-3xl font-semibold">Оформление заказа</h1>
    <section class="cart mt-5">
      <el-table :data="basket" border stripe>
        <el-table-column type="index" align="center"></el-table-column>
        <el-table-column prop="name" label="Название">
          <template slot-scope="scope">
            <div class="flex items-center">
              <img
                v-if="scope.row.images && scope.row.images.length > 0"
                :src="scope.row.images[0]"
                class="hidden md:block w-20 rounded-lg mr-2"
              />
              <div>
                {{ scope.row.name }}
                <br />
                <span class="text-gray-400">{{ scope.row.code }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="200"
          prop="quantity"
          label="Количество"
          align="right"
        ></el-table-column>
        <el-table-column width="200" prop="quantity" label="Цена" align="right">
          <template slot-scope="scope"
            >{{ scope.row.quantity * scope.row.price }} тг</template
          >
        </el-table-column>
      </el-table>
    </section>

    <section class="flex justify-between items-center mt-5">
      <div>
        <div class="font-bold text-xl">Общее: {{ totalBasket }} тг</div>
        <div class="font-bold text-xl">Размер скидки: {{ user.discount }}%</div>
        <div class="font-bold text-xl">Итого: {{ totalAfterDiscount }} тг</div>
      </div>
      <el-button @click="createOrder" round type="warning" plain
        >Оформить заказ</el-button
      >
    </section>
  </div>
</template>

<script>
import { createOrder } from "@/api/order";
export default {
  name: "checkout",
  computed: {
    basket() {
      const basket = this.$store.state.basket;
      return Object.keys(basket).map((key) => ({
        ...basket[key].product,
        quantity: basket[key].quantity,
      }));
    },
    totalBasket() {
      return this.$store.getters["totalBasket"];
    },
    totalAfterDiscount() {
      if (this.user.discount) {
        return this.totalBasket - (this.totalBasket * this.user.discount) / 100;
      }

      return this.totalBasket;
    },
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    async createOrder() {
      await createOrder(this.basket);
    },
  },
};
</script>

<style scoped></style>
