import apiClient from "@/api/apiClient";

export const createOrder = async (items) => {
  const result = await apiClient.post("/api/order", { items });
  return result.data;
};

export const getOrders = async () => {
  const result = await apiClient.get("/api/orders");
  return result.data;
};
