<template>
  <div class="product">
    <figure class="h-40">
      <img class="object-contain w-full h-full" :src="imgSrc" alt="" />
    </figure>
    <div class="px-3 py-2 pb-3">
      <div class="price">
        <span>{{ numberFormat(product.price) }} тг</span>
      </div>
      <div
        class="h-12 overflow-hidden leading-4 font-medium"
        :title="product.name"
      >
        {{ product.name }}
      </div>
      <div class="mt-3 text-right flex justify-between items-center">
        <div class="text-gray-500">{{ product.article }}</div>
        <el-button
          v-if="!isProductInBasket"
          @click="addToBasket"
          size="small"
          type="warning"
          round
        >
          В корзину
        </el-button>
        <el-input-number
          v-else
          :value="productCountInBasket"
          @input="handleChangeProductCount"
          size="small"
        ></el-input-number>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductItem",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    imgSrc() {
      return this.product.images.length
        ? this.product.images[0]
        : require("@/assets/images/placeholder.jpg");
    },
    isProductInBasket() {
      return this.$store.getters["isProductInBasket"](this.product.id);
    },
    productCountInBasket() {
      return this.$store.getters["productCountInBasket"](this.product.id);
    },
  },
  methods: {
    addToBasket() {
      this.$store.commit("addToBasket", this.product);
    },
    handleChangeProductCount(count) {
      console.log("count", count);
      if (count === 0) {
        this.$store.commit("removeProductFromBasket", this.product.id);
        return;
      }
      this.$store.commit("setProductCount", {
        productId: this.product.id,
        count: count,
      });
    },
    numberFormat(number) {
      return new Intl.NumberFormat().format(Math.round(number));
    },
  },
};
</script>

<style lang="scss" scoped>
.product {
  @apply border rounded-lg mb-5 overflow-hidden;
  figure {
    background-color: #e9eef1;
    @apply border-b;
  }
  .price {
    color: #ec9c24;
    @apply font-bold text-lg mb-1;
  }
}
</style>
